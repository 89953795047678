import $ from 'jquery';
import jQuery from 'jquery';

import './scss/app.scss';

window.$ = $;
window.jQuery = jQuery;

$(document).ready(function(){
	console.log('document ready');

	jQuery(function($) {
		$('.navbar .dropdown').hover(function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);

		}, function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);

		});

		$('.navbar .dropdown > a').click(function(){
		location.href = this.href;
		});


		$('.button_1').click(function(){
			$('.leerjaar').removeClass('reveal');
			$('#leerjaar_1').addClass('reveal');
		});

		$('.button_2').click(function(){
			$('.leerjaar').removeClass('reveal');
			$('#leerjaar_2').addClass('reveal');
		});

		$('.button_3').click(function(){
			$('.leerjaar').removeClass('reveal');
			$('#leerjaar_3').addClass('reveal');
		});

		$('.button_4').click(function(){
			$('.leerjaar').removeClass('reveal');
			$('#leerjaar_4').addClass('reveal');
		});

		$('.button_5').click(function(){
			$('.leerjaar').removeClass('reveal');
			$('#leerjaar_5').addClass('reveal');
		});

		$('.button_6').click(function(){
			$('.leerjaar').removeClass('reveal');
			$('#leerjaar_6').addClass('reveal');
		});

		$('.button_7').click(function(){
			$('.leerjaar').removeClass('reveal');
			$('#leerjaar_7').addClass('reveal');
		});

	});

});